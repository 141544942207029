import { useEffect } from 'react';

const useEventListener = (eventName, handler, element = window) => {
  useEffect(() => {
    // Ensure the element supports addEventListener
    const isSupported = element && element.addEventListener;
    if (!isSupported) return;

    // Add event listener
    element.addEventListener(eventName, handler);

    // Remove event listener on cleanup
    return () => {
      element.removeEventListener(eventName, handler);
    };
  }, [eventName, handler, element]);
};

export default useEventListener;
