import {
  convertJsonToNodesAndEdges,
  convertNodesAndEdgesToJson,
} from '../../utils/DataModel';
import dagre from '@dagrejs/dagre';

import React, { useCallback, useEffect, useState, useRef } from 'react';
import ReactFlow, {
  ReactFlowProvider,
  Panel,
  useNodesState,
  useEdgesState,
  useReactFlow,
  addEdge,
  Handle,
  Position,
  MiniMap,
} from 'reactflow';
import 'reactflow/dist/style.css';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ContextMenu from '../ContextMenu/ContextMenu';
import { maxId } from '../../utils/utils';
import { use } from 'marked';

const dagreGraph = new dagre.graphlib.Graph();
dagreGraph.setDefaultEdgeLabel(() => ({}));

const nodeWidth = 300;
const nodeHeight = 50;

const getLayoutedElements = (nodes, edges, direction = 'TB') => {
  const isHorizontal = direction === 'LR';

  dagreGraph.setGraph({
    rankdir: direction.direction,
    align: 'DL',
  });

  nodes.forEach((node) => {
    dagreGraph.setNode(node.id, { width: nodeWidth, height: nodeHeight });
  });

  edges.forEach((edge) => {
    dagreGraph.setEdge(edge.source, edge.target);
  });

  dagre.layout(dagreGraph);

  nodes.forEach((node) => {
    const nodeWithPosition = dagreGraph.node(node.id);
    node.targetPosition = isHorizontal ? 'left' : 'top';
    node.sourcePosition = isHorizontal ? 'right' : 'bottom';

    // We are shifting the dagre node position (anchor=center center) to the top left
    // so it matches the React Flow node anchor point (top left).
    node.position = {
      x: nodeWithPosition.x + 550 - nodeWidth / 2,
      y: nodeWithPosition.y + 450 - nodeHeight / 2,
    };

    return node;
  });

  return { nodes, edges };
};

const CustomNode = ({ data }) => {
  return (
    <div style={{ position: 'relative', maxWidth: '300px', padding: '10px' }}>
      <div style={{ position: 'relative' }}>
        <Handle
          type='target'
          position={Position.Top}
          id='a'
          style={{ zIndex: 10 }}
          isConnectable={true}
        />

        <Accordion>
          <AccordionSummary aria-controls='panel1-content' id='panel1-header'>
            {data.label}
          </AccordionSummary>
          <AccordionDetails>{data.details}</AccordionDetails>
        </Accordion>

        <Handle
          type='source'
          position={Position.Bottom}
          id='b'
          style={{ zIndex: 10 }}
          isConnectable={true}
        />
      </div>
    </div>
  );
};

const nodeTypes = {
  custom: CustomNode,
};

const ReactFlowMindMapInternal = ({ mindMapObject, getTreeState }) => {
  const { fitView } = useReactFlow();
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [updatedMindMapObject, setUpdatedMindMapObject] = useState(null);
  const [menu, setMenu] = useState(null);
  const [isUpdating, setIsUpdating] = useState(false);

  console.log('loading mindMapObject', mindMapObject);

  const ref = useRef(null);

  // console.log('mindMapObject', mindMapObject);

  const getTreeStateInternal = useCallback(
    (newMindMapObject) => {
      if (
        JSON.stringify(newMindMapObject) !==
        JSON.stringify(updatedMindMapObject)
      ) {
        setUpdatedMindMapObject(newMindMapObject);
        getTreeState(newMindMapObject);
      }
    },
    [getTreeState, updatedMindMapObject]
  );

  useEffect(() => {
    if (!mindMapObject || isUpdating) return;
    const { nodes: newNodes, edges: newEdges } =
      convertJsonToNodesAndEdges(mindMapObject);
    setNodes(newNodes);
    setEdges(newEdges);
    console.log('node and edges', newNodes, newEdges);
  }, [mindMapObject, setNodes, setEdges, isUpdating]);

  useEffect(() => {
    if (nodes.length === 0 || edges.length === 0 || isUpdating) return;
    const newMindMapObject = convertNodesAndEdgesToJson(nodes, edges);
    console.log('newMindMapObject', newMindMapObject, nodes, edges);
    getTreeStateInternal(newMindMapObject);
  }, [nodes, edges, getTreeStateInternal, isUpdating]);

  useEffect(() => {
    console.log('nodes', nodes);
  }, [nodes, edges]);

  const onLayout = useCallback(
    (direction) => {
      setIsUpdating(true);
      const layouted = getLayoutedElements(nodes, edges, { direction });
      setNodes([...layouted.nodes]);
      setEdges([...layouted.edges]);

      window.requestAnimationFrame(() => {
        fitView();
        //setIsUpdating(false);
      });
    },
    [nodes, edges, setNodes, setEdges, fitView]
  );

  const onConnect = useCallback(
    ({ source, target }) => {
      if (source === target) {
        return;
      }

      setEdges((eds) =>
        nodes.reduce(
          (acc, node) => {
            if (node.id === source || node.selected) {
              if (node.id !== target) {
                acc.push({ source: node.id, target });
              }
            }
            return acc;
          },
          [...eds]
        )
      );
    },
    [nodes, setEdges]
  );

  const onNodeContextMenu = useCallback(
    (event, node) => {
      event.preventDefault();
      const menuHeight = 200; // Approximate height of the context menu
      const menuWidth = 200; // Approximate width of the context menu
      const top = Math.min(event.clientY, window.innerHeight - menuHeight);
      const left = Math.min(event.clientX, window.innerWidth - menuWidth);

      setMenu({
        id: node.id,
        top,
        left,
        data: node.data,
      });
    },
    [setMenu]
  );

  const onAdd = useCallback(() => {
    const getNodeId = () => maxId(nodes) + 1;
    const label = prompt('Enter node label');

    let position = { x: window.innerWidth / 2, y: window.innerHeight / 2 };

    if (nodes.length > 0) {
      const avgPosition = nodes.reduce(
        (avg, node) => {
          avg.x += node.position.x;
          avg.y += node.position.y;
          return avg;
        },
        { x: 0, y: 0 }
      );

      position = {
        x: avgPosition.x / nodes.length,
        y: avgPosition.y / nodes.length,
      };
    }

    const newNode = {
      id: `${getNodeId()}`,
      data: { label: label || 'Added node', description: 'Description' },
      position,
      type: 'custom',
    };

    console.log('newNode', newNode);
    setNodes((nds) => nds.concat(newNode));
  }, [nodes, setNodes]);

  const onPaneClick = useCallback(() => setMenu(null), [setMenu]);

  return (
    <ReactFlowProvider>
      <ReactFlow
        ref={ref}
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onConnect={onConnect}
        nodeTypes={nodeTypes}
        onNodeContextMenu={onNodeContextMenu}
        fitView
      >
        <Panel position='top-right'>
          <button onClick={() => onLayout('TB')}>Vertical Layout</button>
          <button onClick={() => onLayout('LR')}>Horizontal Layout</button>
          {/* <button onClick={() => onAdd()}>Add Node</button> */}
        </Panel>
        <MiniMap zoomable pannable />

        {menu && <ContextMenu onClick={onPaneClick} {...menu} />}
      </ReactFlow>
    </ReactFlowProvider>
  );
};

const ReactFlowMindMap = ({ mindMapObject, getTreeState }) => {
  return (
    <ReactFlowProvider>
      <ReactFlowMindMapInternal
        mindMapObject={mindMapObject}
        getTreeState={getTreeState}
      />
    </ReactFlowProvider>
  );
};

export default ReactFlowMindMap;
