import React, { useState } from 'react';
import { Paper, IconButton, Menu, MenuItem } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import ReactMarkdown from 'react-markdown';

function TextPanel({ title, text, onClose }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedText, setSelectedText] = useState('');
  const [anchorPosition, setAnchorPosition] = useState({ top: 0, left: 0 });

  const handleRightClick = (event) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
    setSelectedText(window.getSelection().toString());
    setAnchorPosition({ top: event.clientY, left: event.clientX });
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSearch = () => {
    const url = `https://www.google.com/search?q=${encodeURIComponent(
      selectedText
    )}`;
    window.open(url, '_blank');
    handleClose();
  };

  return (
    <Paper style={{ padding: '10px', height: '80%', position: 'relative' }}>
      <IconButton
        style={{ position: 'absolute', right: 0, top: 0 }}
        onClick={onClose}
      >
        <CloseIcon />
      </IconButton>
      {title && <h2>{title}</h2>}
      <div onContextMenu={handleRightClick}>
        <ReactMarkdown>{text}</ReactMarkdown>
      </div>
      <Menu
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorReference='anchorPosition'
        anchorPosition={anchorPosition}
      >
        <MenuItem onClick={handleSearch}>
          Search Google for "{selectedText}"
        </MenuItem>
      </Menu>
    </Paper>
  );
}

export default TextPanel;
