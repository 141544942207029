import axios from 'axios';
import { initialData, expandedTreeData, usage } from '../data';

export const callLLM = async (prompt, apiKey) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API}/create_mind_map`,
      {
        topic: prompt + '\n',
        apiKey: apiKey,
      }
    );

    if (res.data.error) {
      console.error('here', res.data.error);
      throw new Error(res.data.error);
    }

    const response = JSON.parse(res.data.mind_map);
    const usage = res.data.usage;
    console.log('response and usage', response, usage);
    return { response, usage };
  } catch (err) {
    throw new Error(
      `An error occurred while processing the request: ${err.response.data.error}`
    );
  }
};

export const callLLMTest = async (prompt) => {
  try {
    return { response: initialData, usage: usage };
  } catch (err) {
    console.error(err);
    throw err;
  }
};

export const expandOnTopicApi = async (prompt, context) => {
  try {
    const res = await axios.post('http://localhost:4000/api/deepdive', {
      topic: prompt,
      context: context,
    });
    return res.data;
    //return data;
  } catch (err) {
    console.error(err);
    throw err;
  }
};

export const expandTree = async (prompt, context) => {
  try {
    const res = await axios.post('http://localhost:4000/api/expand_tree', {
      topic: prompt,
      context: context,
    });

    return JSON.parse(res.data.mind_map);
    // return expandedTreeData;
  } catch (err) {
    console.error(err);
    throw err;
  }
};

export const expandOnTopicApiStreaming = async (
  topic,
  context,
  apiKey,
  updateFunction
) => {
  try {
    const res = await fetch(`${process.env.REACT_APP_API}/brainstorm`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ context, topic, apiKey }),
    });

    const reader = res.body.getReader();
    const decoder = new TextDecoder();

    while (true) {
      const { done, value } = await reader.read();
      if (done) break;

      const chunk = decoder.decode(value, { stream: true });
      updateFunction((prev) => prev + chunk);
    }
  } catch (error) {
    console.error('Error fetching data:', error);
  }
};

export const createMindMapApiStreaming = async (
  prompt,
  apiKey,
  updateFunction
) => {
  try {
    const res = await fetch(
      `${process.env.REACT_APP_API}/create_mind_map_streaming`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ prompt, apiKey }),
      }
    );

    const reader = res.body.getReader();
    const decoder = new TextDecoder();

    while (true) {
      const { done, value } = await reader.read();
      if (done) break;

      const chunk = decoder.decode(value, { stream: true });
      updateFunction((prev) => prev + chunk);
    }
  } catch (error) {
    console.error('Error fetching data:', error);
  }
};
