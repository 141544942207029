import React, { useEffect } from 'react';

const HeapScriptLoader = () => {
  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      window.heapReadyCb = window.heapReadyCb || [];
      window.heap = window.heap || [];
      window.heap.load = function (e, t) {
        window.heap.envId = e;
        window.heap.clientConfig = t = t || {};
        window.heap.clientConfig.shouldFetchServerConfig = !1;
        const a = document.createElement('script');
        a.type = 'text/javascript';
        a.async = !0;
        a.src = 'https://cdn.us.heap-api.com/config/' + e + '/heap_config.js';
        const r = document.getElementsByTagName('script')[0];
        r.parentNode.insertBefore(a, r);
        const n = [
          'init',
          'startTracking',
          'stopTracking',
          'track',
          'resetIdentity',
          'identify',
          'getSessionId',
          'getUserId',
          'getIdentity',
          'addUserProperties',
          'addEventProperties',
          'removeEventProperty',
          'clearEventProperties',
          'addAccountProperties',
          'addAdapter',
          'addTransformer',
          'addTransformerFn',
          'onReady',
          'addPageviewProperties',
          'removePageviewProperty',
          'clearPageviewProperties',
          'trackPageview',
        ];
        const i = function (e) {
          return function () {
            const t = Array.prototype.slice.call(arguments, 0);
            window.heapReadyCb.push({
              name: e,
              fn: function () {
                window.heap[e] && window.heap[e].apply(window.heap, t);
              },
            });
          };
        };
        for (let p = 0; p < n.length; p++) window.heap[n[p]] = i(n[p]);
      };
      window.heap.load('400415576');
    }
  }, []);

  return null;
};

export default HeapScriptLoader;
