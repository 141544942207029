import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { logErrorToMyService } from './utils/utils';

const root = ReactDOM.createRoot(document.getElementById('root'));

// window.addEventListener('unhandledrejection', function (event) {
//   console.error('Unhandled Rejection:', event.reason);
//   // Log to a reporting service
//   logErrorToMyService(event.reason);
// });

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
