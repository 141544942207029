// src/App.js
import React, { useState, useEffect } from 'react';
import ReactFlowMindMap from '../../components/ReactFlowMindMap/ReactFlowMindMap';
import { callLLM, expandTree } from '../../utils/LLMCall';
import { logErrorToMyService } from '../../utils/utils';
import { TextField, Button, CircularProgress } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import { Paper } from '@material-ui/core';
import { Box } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import InputAdornment from '@material-ui/core/InputAdornment';
import { sampleData } from '../../utils/DataModel';
import useEventListener from '../../hooks/useEventListener';
import { set } from 'react-ga';
import { expandOnTopicApiStreaming } from '../../utils/LLMCall';
import TextPanel from '../../components/TextPanel/TextPanel';

function AppContainer() {
  const [inputValue, setInputValue] = useState('');
  const [loading, setLoading] = useState(false);

  const [initialData, setInitialData] = useState();
  const [updatedMindMapObject, setUpdatedMindMapObject] = useState(null);
  const [openAIKey, setOpenAIKey] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [openBrainStormDialog, setOpenBrainStormDialog] = useState(false);
  const [brainstormData, setBrainstormData] = useState('');
  const [nodeClicked, setNodeClicked] = useState(null);

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (openAIKey.length === 0) {
      setErrorMsg('Please enter a valid OpenAI API Key');
      setOpenSnackbar(true);
      return;
    }
    setErrorMsg('');
    setLoading(true);
    let response;
    let usage;
    try {
      ({ response, usage } = await callLLM(inputValue, openAIKey));
      // console.log('Response and usage', response, usage);

      setLoading(false);
      setInitialData(response);
    } catch (err) {
      setLoading(false);
      setErrorMsg(err.message);
      setOpenSnackbar(true);
      logErrorToMyService(err);
    }
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handlMindMapObjectChange = (newMindMapObject) => {
    setUpdatedMindMapObject(newMindMapObject);
  };

  const handleOpenAIKeyChange = (e) => {
    setOpenAIKey(e.target.value);
  };

  useEffect(() => {
    if (openAIKey.length > 0) {
      localStorage.setItem('openAIKey', openAIKey);
    }
  }, [openAIKey]);

  useEffect(() => {
    const key = localStorage.getItem('openAIKey');
    if (key && key.length > 0) {
      setOpenAIKey(key);
    }
  }, []);

  const handleButtonClicks = (event) => {
    if (event.target.id === 'brainstorm_button') {
      // console.log(
      //   'Button B clicked',
      //   updatedMindMapObject,
      //   event.target,
      //   event.target.dataset.nodeData
      // );
      setBrainstormData('');
      const topic = JSON.parse(event.target.dataset.nodeData);
      setNodeClicked(event.target.dataset.nodeData);
      console.log('Topic', topic);
      setOpenBrainStormDialog(true);
      expandOnTopicApiStreaming(
        topic.label,
        JSON.stringify(updatedMindMapObject),
        openAIKey,
        setBrainstormData
      );

      // Handle the button click event here
    }
  };

  useEffect(() => {
    console.log('Updated mind map object 2', updatedMindMapObject);
    //setInitialData(updatedMindMapObject);
  }, [updatedMindMapObject]);

  useEventListener('click', handleButtonClicks);

  return (
    <Box margin={1} style={{ height: '100%' }}>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        message={errorMsg}
      />
      <Grid container style={{ height: '100%' }}>
        <Grid item xs={12}>
          <Grid container style={{ height: '80%' }}>
            <Grid item xs={openBrainStormDialog ? 8 : 12}>
              <Paper
                style={{
                  border: '1px solid black',
                  backgroundColor: '#f5f5f5',
                  height: '100%',
                }}
              >
                {loading && <CircularProgress />}
                <ReactFlowMindMap
                  mindMapObject={initialData}
                  getTreeState={handlMindMapObjectChange}
                />
                <Box marginTop={2}>
                  <Grid container spacing={2}>
                    <Grid item xs={8}>
                      <form
                        onSubmit={handleSubmit}
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          gap: '10px',
                        }}
                      >
                        <TextField
                          value={inputValue}
                          onChange={handleInputChange}
                          label='Prompt'
                          variant='outlined'
                          multiline
                          minRows={1} // You can adjust the number of rows as needed
                        />
                        <Button
                          type='submit'
                          variant='contained'
                          color='primary'
                        >
                          Submit Prompt
                        </Button>
                      </form>
                    </Grid>
                    <Grid item xs={4}>
                      <form
                        onSubmit={handleSubmit}
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          gap: '10px',
                        }}
                      >
                        <TextField
                          value={
                            openAIKey.length < 2
                              ? openAIKey
                              : openAIKey[0] +
                                '*'.repeat(openAIKey.length - 2) +
                                openAIKey.slice(-1)
                          }
                          onChange={handleOpenAIKeyChange}
                          label='OpenAI API Key (will not be stored)'
                          variant='outlined'
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position='end'>
                                <Tooltip
                                  title='Enter your OpenAI Key here. 
                                This is required to make API calls. We DO NOT store this key'
                                >
                                  <IconButton aria-label='help'>
                                    <HelpOutlineIcon />
                                  </IconButton>
                                </Tooltip>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </form>
                    </Grid>
                  </Grid>
                </Box>
                <Box marginTop={2}></Box>
              </Paper>
            </Grid>
            {openBrainStormDialog && (
              <Grid item xs={4}>
                <TextPanel
                  title={JSON.parse(nodeClicked).label}
                  text={brainstormData}
                  onClose={() => setOpenBrainStormDialog(false)}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}

export default AppContainer;
