import axios from 'axios';

function findParents(nodes, edges, nodeId, parents = []) {
  // Find the edge where the node is the target
  const edge = edges.find((edge) => edge.target === nodeId);

  if (!edge) {
    // If no such edge exists, we've reached a root node and return the parents
    return parents;
  } else {
    // If such an edge exists, we add the source node to the parents
    const parentNode = nodes.find((node) => node.id === edge.source);
    parents.push(parentNode);

    // And continue the search with the source node
    return findParents(nodes, edges, edge.source, parents);
  }
}

export function maxId(nodes) {
  console.log('maxId nodes:', nodes);
  return nodes.reduce((max, node) => Math.max(max, Number(node.id)), 0);
}

export function addObjectToTree(tree, newChild) {
  let maxId = 0;

  const traverse = (node) => {
    maxId = Math.max(maxId, node.id);
    if (node.children) {
      node.children.forEach(traverse);
    }
  };

  traverse(tree);

  const recursiveAdd = (node) => {
    if (node.name === newChild.name) {
      newChild.children.forEach((child, index) => {
        child.id = maxId + 1 + index;
      });
      node.children = newChild.children;
      return true;
    }
    if (node.children) {
      for (let child of node.children) {
        if (recursiveAdd(child)) {
          return true;
        }
      }
    }
    return false;
  };

  recursiveAdd(tree);
  return tree;
}

export async function logErrorToMyService(error) {
  console.error('Error caught in logErrorToMyService:', error);
  // Send the error to an api endpoint
  const errorData = JSON.stringify(error, Object.getOwnPropertyNames(error));
  await axios.post(`${process.env.REACT_APP_API}/error`, {
    error: errorData,
  });
}
