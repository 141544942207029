// src/App.js
import { React, useEffect } from 'react';
import AppContainer from './components/Container/AppContainer';
import Layout from './components/Layout/Layout';
import HeapScriptLoader from './components/HeapScript/HeapScript';
// import ReactGA from 'react-ga';

function App() {
  console.log('App.js', process.env.REACT_APP_API);

  // useEffect(() => {
  //   ReactGA.initialize('G-34JK401QSZ'); // Replace 'YOUR_TRACKING_ID' with your actual Google Analytics tracking ID
  //   ReactGA.pageview(window.location.pathname + window.location.search);
  // }, []);

  return (
    <>
      <Layout>
        <AppContainer />
        <HeapScriptLoader />
      </Layout>
    </>
  );
}

export default App;
