export const parseDataToTree = (data) => {
  const nodes = [];
  const links = [];

  const traverse = (node, parent = null) => {
    nodes.push({
      id: node.id,
      name: node.name,
      parent: parent ? parent.id : null,
    });

    if (parent) {
      links.push({ source: parent.id, target: node.id });
    }

    if (node.children) {
      node.children.forEach((child) => traverse(child, node));
    }
  };
  traverse(data);
  return { nodes, links };
};

export const addNodeToTree = (tree, parentId, newNode) => {
  const traverse = (node) => {
    if (node.id === parentId) {
      node.children = node.children || [];
      node.children.push(newNode);
      return true;
    }

    if (node.children) {
      for (let child of node.children) {
        if (traverse(child)) {
          return true;
        }
      }
    }

    return false;
  };

  traverse(tree);
};

export function convertJsonToNodesAndEdges(json) {
  console.log('convertJsonToNodesAndEdges', json);
  const nodes = [];
  const edges = [];
  const levelOffsets = {};

  // Constants to adjust positioning
  const xSpacing = 400; // Horizontal spacing between nodes
  const ySpacing = 100; // Vertical spacing between levels

  function setPosition(node, level, offset) {
    const { id, name, details, children } = node;

    // Initialize the horizontal offset tracking for this level if not already initialized
    if (!levelOffsets[level]) {
      levelOffsets[level] = [];
    }

    // Find the nearest available offset for this level
    let position = { x: offset, y: level * ySpacing };
    while (
      levelOffsets[level].some((pos) => Math.abs(pos - position.x) < xSpacing)
    ) {
      offset += xSpacing;
      position = { x: offset, y: level * ySpacing };
    }
    levelOffsets[level].push(position.x);

    nodes.push({
      id: id.toString(),
      data: { label: name, details: details },
      position,
      type: 'custom', // Replace with actual node type if needed
    });

    if (children && Array.isArray(children)) {
      const childCount = children.length;
      children.forEach((child, index) => {
        const childOffset =
          position.x + (index - (childCount - 1) / 2) * xSpacing;
        edges.push({
          id: `e${id}-${child.id}`,
          source: id.toString(),
          target: child.id.toString(),
          type: 'default', // Replace with actual edge type if needed
          animated: true,
        });
        setPosition(child, level + 1, childOffset);
      });
    }
  }

  const initialOffset = window.innerWidth / 2; // Center the root node
  setPosition(json, 5, initialOffset);

  return { nodes, edges };
}

export function convertNodesAndEdgesToJson(nodes, edges) {
  const idToNodeMap = new Map(
    nodes.map((node) => [
      node.id,
      { id: node.id, name: node.data.label, details: node.data.details },
    ])
  );
  const idToChildrenMap = new Map();

  edges.forEach((edge) => {
    if (edge.source === edge.target) {
      // Ignore edges where the source and target are the same
      return;
    }

    const sourceNodeChildren = idToChildrenMap.get(edge.source) || [];
    sourceNodeChildren.push(idToNodeMap.get(edge.target));
    idToChildrenMap.set(edge.source, sourceNodeChildren);
  });

  nodes.forEach((node) => {
    const nodeChildren = idToChildrenMap.get(node.id) || [];
    idToNodeMap.get(node.id).children = nodeChildren;
  });

  const rootNode = nodes.find(
    (node) => !edges.some((edge) => edge.target === node.id)
  );

  if (!rootNode) {
    throw new Error('No root node found');
  }

  const rootNodeId = rootNode.id;

  return idToNodeMap.get(rootNodeId);
}

export const getMaxId = (json) => {
  let maxId = json.id;

  const traverse = (node) => {
    if (node.id > maxId) {
      maxId = node.id;
    }

    if (node.children) {
      node.children.forEach(traverse);
    }
  };

  traverse(json);

  return maxId;
};

export const sampleData = {
  id: 1,
  name: 'How to use this App?',
  details: 'here are the steps to take to use this app',
  children: [
    {
      id: 2,
      name: '1. Add OpenAI API key',
      details:
        'We need an API key to make calls to OpenAI API on your behalf. We do not store this key',
      children: [],
    },
    {
      id: 3,
      name: '2. Write a prompt about a topic',
      details: 'write a prompt about a topic you want to learn more about',
      children: [],
    },
    {
      id: 4,
      name: '3. Click on the submit button',
      details:
        'Click on submit button. It make take a minute to generate the response',
      children: [],
    },
  ],
};
